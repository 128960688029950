import React from 'react';
import appContext from '../../context/app-context';


class Copyright extends React.Component {

    static contextType = appContext;

    render () {
        return (
        <React.Fragment>
            <div className="app-footer" >
                    <div className="version-container" >
                        <div className="ui label">
                            <span className="detail">{this.context.version}</span>
                        </div>
                    </div>
                    <div className="it-can-container" >
                        <span className="ui image label">
                            Edited by IT-CAN
                            <img src={`${this.context.basename}/images/it-can-32.png`} className="it-can-logo" alt="" />
                        </span>
                    </div>
            </div>
        </React.Fragment>
        )
    }
}
export default Copyright;