import React from 'react';
import StoreCard from "./StoreCard";
import api from 'soprano-ws-client';
import { Loader, Error } from './common/common';
import appContext from '../context/app-context';
import Copyright from './common/Copyright';
import ClientLogo from './common/ClientLogo';

class StoresList extends React.Component {

    static contextType = appContext;
    state = {
        stores : [],
        status : "loading"
    }

    // Get stores list from api when component did mount
    componentDidMount = () =>{
        this.getStoresList();
    }

    // Get list of stores from server
    getStoresList = () =>{
        let offlinData = localStorage.getItem("offlineData") === null ? ({ stores : { list: [] }, users : [] }) : 
                                                                        JSON.parse(localStorage.getItem("offlineData"));
        let fetchStoresList = new Promise(api.store.list('token',`/ws/magasin`));

        fetchStoresList.then((jsonData)=>{
            if (jsonData.status === true){
                offlinData.stores.list = jsonData.data;
                localStorage.setItem('offlineData',JSON.stringify(offlinData));
                this.setState({
                    stores: jsonData.data,
                    status: "success"
                });
            }else{
                this.setState({
                    stores : offlinData.stores.list,
                });
            }
        }).catch((error)=>{
            this.setState({
                stores   : offlinData.stores.list,
                status   : "success",
                errormsg : error
            }); 
        })
    }

    // Render the list of stores fetched from server   
    renderStores = () => {
        return this.state.stores.map((store)=> {
            return <StoreCard key={store.id} store={store} />
        });
    }

    /** Switch status state Render Magasins page 
     * loading : render the <Loader /> component
     * error : render the <Error /> component
     * default : render stores 
     */
	render () {

        switch(this.state.status) {
            case 'loading':
                return (
                    <Loader />
                );
            case 'error':
                return (
                    <Error />
                );
            default :
            return (
                <div className="magasins">
                    <ClientLogo/>
                    <div className="container">
                        {this.renderStores()}                
                    </div>
                    <Copyright/>
                </div>
            )            
        }
	}
}

export default StoresList;