import React      from 'react';
import appContext from '../context/app-context';
import { StockLink, StockAdjustLink } from 'module-stock';
import { UtilisationLink }            from 'module-utilisation';
import { ApprovisionLink }            from 'module-approvisionnement';
import { DefinitionLink }             from 'module-definition-soprano';
import { PreparationLink }            from 'module-preparation';
import { VenteLink }                  from 'module-vente';
import Copyright                      from './common/Copyright';
import ClientLogo from './common/ClientLogo';

class Home extends React.Component {
    static contextType = appContext;

	render () {
        return (
            <div className="accueil">
                <ClientLogo/>
                <div className="container">
                        <VenteLink 
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify}  
                        />
                        <PreparationLink 
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify}  
                        />
                        <UtilisationLink
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify}  
                        />
                        <ApprovisionLink 
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify} 
                        />
                        <StockAdjustLink
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify} 
                        />
                        <StockLink
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify} 
                        />
                        <DefinitionLink
                            user      = {this.context.user}
                            basename  = {this.context.basename}
                            setAccess = {this.context.setAccess}
                            notify    = {this.context.notify} 
                        />                        
                </div>
                <Copyright />
            </div>
        )            
        
	}
}

export default Home;