import React,{Component} from "react";
import {Link} from 'react-router-dom';
import appContext from "../context/app-context";

class StoreCard extends Component {
    static contextType =  appContext;
    render() {
        return(
            <Link to="/utilisateur">
                <div className="ui card" onClick={() => {this.context.selectStore(this.props.store)}} >
                    <div className="content">
                        <div className="header">{this.props.store.nom}</div>
                    </div>
                    <div className="image-container">
                        <img src={this.context.basename + this.props.store.image} className="ui circular image" alt='warhouse avatar'/>
                    </div>
                </div>
            </Link>
        )
    }
}

export default StoreCard;