import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import AppContext from '../../context/app-context';

class Breadcrumb extends React.Component {
    state = {
        mode: "minimize"
    }

    componentWillUpdate = (prevProps) => {
        if(this.props.location !== prevProps.location){
            //this.context.getNumberOfAlert();
        }
        if (this.state.mode === "normal") this.setState({mode: "minimize"});   
    }

    // Set the title of the first step in the bradcrumb
    getTitleOne = () => {
        switch(this.props.location.pathname){
            case '/utilisateur' : return "Magasins";
            case '/mot-de-passe' : return "Magasins";
            default : return "Accueil"
        }
    }

    // Set the icon of the first step in the breadcrumb
    getIconOne = () =>{
        switch(this.props.location.pathname){
            case '/utilisateur' : return "warehouse";
            case '/mot-de-passe' : return "warehouse";
            default : return "home"
        }
    }

    getLinkOne = () =>{
        switch(this.props.location.pathname){
            case '/utilisateur' : return "/";
            case '/mot-de-passe' : return "/";
            default : return "/accueil"
        }
    }

    // Set the title of the second step in the breadcrumb
    getTitleTwo = () =>{
        switch(this.props.location.pathname){
            case '/utilisateur' : return "Utlisateur";
            case '/mot-de-passe' : return "Utlisateur";
            case '/ajustement-stock' : return "Ajustement Stock";
            case '/stock' : return "Stock";
            case '/préparation' : return "Préparation";
            case '/utilisation' : return "Utilisation";
            case '/approvisionnement' : return "Approvisionnement";
            case '/définition' : return "Définition";
            case '/vente' : return "Vente";
            default : return '';
        }
    }

    // Set, the icon of the second step in the breadcrumb 
    getIconTwo = () =>{
        switch(this.props.location.pathname){
            case '/utilisateur' : return <i className="teal users icon"></i>;
            case '/mot-de-passe' : return <i className="teal users icon"></i>;
            case '/ajustement-stock' : return (
                <React.Fragment> <img src={`${this.context.basename}/images/stock.png`} className="nav-img" alt="" />
                    <img src={`${this.context.basename}/images/ajustement.png`}  className="adjust-img-nav" alt="ajustement" />
                </React.Fragment>
                );
            case '/vente' : return <img src={`${this.context.basename}/images/vente-produit.png`} className="nav-img" alt="" />;
            case '/stock' : return <img src={`${this.context.basename}/images/stock.png`} className="nav-img" alt="" />;
            case '/préparation' : return <img src={`${this.context.basename}/images/preparation.png`} className="nav-img" alt="" />;
            case '/utilisation' : return <img src={`${this.context.basename}/images/utilisation.png`} className="nav-img" alt="" />;
            case '/approvisionnement' : return <img src={`${this.context.basename}/images/approvision.png`} className="nav-img" alt="" />;
            case '/définition' : return <img src={`${this.context.basename}/images/definition.png`} className="nav-img" alt="" /> 
            default : return '';          
        }
    }

    // This function will toggle the breadcrumb  
    toggle = () =>{
        if (this.state.mode === "minimize") return this.setState({mode: "normal"})
        this.setState({mode: "minimize"})  
    }
    
    render(){
        switch(this.props.location.pathname){
            case '/' : return null;
            case '/accueil' : return (
                <div className="nav-bar">
                    <div className="ui mini steps">
                        <div className="active step">
                            <i className="home icon teal"></i>
                            <div className="content">
                                <div className="title">Accueil</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) ;
            default : 
                return (
                    <div className="nav-bar">
                        <div className="ui mini steps">
                            <Link to={this.getLinkOne()} className={"step"} >
                                <i className={this.state.mode === "minimize" ? this.getIconOne()+" icon margin-right" : this.getIconOne()+" icon"}></i>
                                <div className={this.state.mode === "minimize" ? " content hide-element" : " content"}>
                                    <div className="title">{this.getTitleOne()}</div>
                                </div>
                            </Link>
                            <div onClick={this.toggle} className="active step cursor-pointer">
                                {this.getIconTwo()}                        
                                <div className={this.state.mode === "minimize" ? " content hide-element" : " content margin-left"}>
                                    <div className="title">{this.getTitleTwo()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    }
}

export default withRouter(Breadcrumb);
Breadcrumb.contextType = AppContext;