import React from 'react';

// Server config data
export const server = {
    images_path : '/app/images/',
}

export const config = {
    limit : 5
}

export function removeDuplicatesFromArray(array) {
    return array.filter((v,i) => array.indexOf(v) === i);
}

// Error message component
export class Error extends React.Component {
    render(){
        return (
            <div className="utilisateur" >
                <div className="content red message">
                    <div className="ui red massive message" >Oops! Une erreur est survenue</div>
                </div>
            </div>
        );
    } 
}

// Loader component
export class Loader extends React.Component {
    render(){
        return (
            <div className="ui">
                <div className="ui active inverted dimmer">
                    <div className="ui text loader">Loading</div>
                </div>
                <p></p>
            </div>
        );
    }
}
