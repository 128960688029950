import React, { Component } from 'react';
import { ToastContainer }   from 'react-toastify';
import {BrowserRouter as Router ,Route, Switch} from 'react-router-dom';

import { Stock, StockAdjustment } from 'module-stock';
import { Utilisation }            from 'module-utilisation';
import { Preparation }            from 'module-preparation';
import { Approvisionnement }      from 'module-approvisionnement';
import { Definition }             from 'module-definition-soprano';
import { Vente }                  from 'module-vente';

import StoresList from "./components/StoresList";
import UsersList  from "./components/UsersList";
import Home       from "./components/Home";
import Password   from "./components/Password";

import Alert      from './components/common/Alert';
import Breadcrumb from './components/common/Breadcrumb';
import Logout     from './components/common/Logout';

import AppContext  from './context/app-context';
import GlobalState from './context/GlobalState';
import                  'react-toastify/dist/ReactToastify.css';
class App extends Component {

render() {

    return (
      <GlobalState >
          <Router basename={'/app'}>
          <div className="App">
            <Breadcrumb />
            <Alert />
            <Logout />
            <ToastContainer autoClose={3000} hideProgressBar={true} />
          
            <Switch>
                <Route exact path='/'             component={ () => <StoresList/> } />
                <Route exact path='/utilisateur'  component={ () => <UsersList /> } />
                <Route exact path='/mot-de-passe' component={ (props) => <Password {...props} /> } />
                <Route exact path='/accueil'      component={ () => <Home /> } />

              <AppContext.Consumer >
                { context => (
                  <React.Fragment>
                    <Route exact path='/vente'             component={ (props) => <Vente             token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props}/> } />
                    <Route exact path='/préparation'       component={ (props) => <Preparation       token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props}/> } />
                    <Route exact path='/utilisation'       component={ (props) => <Utilisation       token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props}/> } />
                    <Route exact path='/approvisionnement' component={ (props) => <Approvisionnement token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props}/>} />
                    <Route exact path='/stock'             component={ (props) => <Stock             token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props} />} />
                    <Route exact path='/ajustement-stock'  component={ (props) => <StockAdjustment   token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props}/> } />
                    <Route exact path='/définition'        component={ (props) => <Definition        token = {context.user.token} notify={context.notify} basename  = {context.basename} {...props} /> } />
                  </React.Fragment>
                )}
              </AppContext.Consumer>
            </Switch>
          </div>
        </Router>
      </GlobalState>
     
    );
  }
}

export default App;
