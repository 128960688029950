import React              from 'react';
import {Link, withRouter} from 'react-router-dom';
import appContext         from '../../context/app-context';
import api                from 'soprano-ws-client';

class Alert extends React.Component {
    
    state = {
        alerts_number : 0,
        error_msg     : '',
    }

    componentWillUpdate = (prevProps) => {
        if(this.props.location !== prevProps.location){
            this.getNumberOfAlert();
        } 
    }

    // Get number of alerts
    getNumberOfAlert = () => {
        // if there is no token provided prevent the user from getting data until he connect
        if (this.context.user.token === null) {
            return;
        }
        let fetchNumberOfAlert = new Promise (api.stock.alerts_number(this.context.user.token, '/ws/stock/seuil'));
    
        fetchNumberOfAlert.then((jsonData) =>{
            if(jsonData.status){
                this.setState({alerts_number : jsonData.data.list[0].count});
            }
        })
        fetchNumberOfAlert.catch((error) =>{
            this.setState({error_msg : error})
        })
    }


    render(){
        switch(this.props.location.pathname){
            case '/utilisateur' : return null;
            case '/mot-de-passe' : return null;
            case '/stock' : return null;
            case '/' : return null;
            default : return (
                <Link to={{ pathname: '/stock', search: "?type_article=tous&sous_seuil=true&page_offset=1&page_count=10" }}>
                    <div className="alert-container">
                        <i className="big icon grey bell"></i> 
                        <div className="ui red circular labels">
                            <div className="ui large label">{this.state.alerts_number}</div>
                        </div> 
                    </div>
                </Link>
            );
        }
    }
}

export default withRouter(Alert);
Alert.contextType = appContext;