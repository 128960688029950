import React              from 'react';
import {Link, withRouter} from 'react-router-dom';
import appContext         from '../../context/app-context';

class Logout extends React.Component {

    /** Switch between classes to indicate that:
     * User is connceted
     * User isn't connected
     */
    getClassName = () =>{
        return this.context.user.connected === false ? "pave-numerique user-container user-not-connected" : "user-container"
    }

    render(){
        switch(this.props.location.pathname){
            case '/utilisateur' : return null;
            case '/' : return null;
            
            default : return (
                    <div className={this.getClassName()}>
                        <Link to="/utilisateur">
                            <img onClick={this.context.onLogout} src={this.context.basename + this.context.user.avatar} className="ui image circular" alt="utilisateur avatar" />
                            <i className="large red sign out alternate icon signout-icon"></i>
                        </Link>
                        <Link to={{pathname: "/mot-de-passe",state: { initializePwd: true }}}>
                            <div className="username" >{ this.context.user.prenom ? this.context.user.prenom : this.context.user.nom_utilisateur}</div>
                        </Link>
                    </div>
            );
        }   
    }
}

export default withRouter(Logout);
Logout.contextType = appContext;