import React      from 'react';
import AppContext from '../context/app-context';
import api        from 'soprano-ws-client';
import Copyright  from './common/Copyright';
import CryptoJS   from 'crypto-js';

class Home extends React.Component {
    
    static contextType = AppContext;
    state = {
        password: '',
        msg: '',
        erreur_msg:'',
        initializePwd: this.props.location.state.initializePwd,
        new_password: '',
        initializePwd_step: 1
    }

    login = () => {
        // The password will be Hached with a secret key brfore it will be send to the login api
        let hachedPwd = CryptoJS.HmacSHA256(`${this.state.password}`,"Secret Passphrase Soprano").toString();
        let url  = `/ws/auth/login`;
        let body = `nom_utilisateur=${this.context.user.nom_utilisateur}&mot_de_passe=${hachedPwd}&code_magasin=${this.context.store.code}`;
        let fetchLogin = new Promise(api.auth.login(this.context.user.token, url, body));

        fetchLogin.then((jsonData) =>{
            if (jsonData.status === true){
                this.context.onLogin(jsonData,hachedPwd,"Utilisateur connecté");
                this.setState({
                    status: "success"
                },()=>{this.props.history.push("/accueil");});
            }else{
                this.setState({
                    password:'',
                    msg:'Mot de passe incorrect'
                });
            }
        });
        fetchLogin.catch((error) => {
            this.setState({
                status: "error",
                msg: error
            });
        })
    }

    // This function intialize the password of the user after he connect
    initializePwd = () => {
        if (this.context.user.nom === null) {
            return ( this.setState({ msg:"Aucun utilisateur selectioné" }) )
        }
        switch (this.state.initializePwd_step) {
            case 1:{
                    // the taped password will be hached before it will be compared to the saved one in the Globale state
                    if(CryptoJS.HmacSHA256(`${this.state.password}`,"Secret Passphrase Soprano").toString() === this.context.user.password){
                    this.setState({ initializePwd_step:2, password:'' });
                    break;
                } else {
                        this.setState({msg:"Mot de passe incorrect", password:''}); 
                        break; 
                    }
            }
        
            case 2: {
                if (this.state.password.length < 4) {
                    (this.setState({password:'' , msg:'Le mot de passe doit être de 4 caractères'}));
                    break;
                } else { 
                    let new_password = this.state.password;
                     this.setState({ initializePwd_step:3, new_password: new_password, password:'' });
                     break;

                }
            }
            case 3: {
                if (this.state.new_password === this.state.password){
                    // The new password will be hached and saved as it is in the database
                    let hachedPwd = CryptoJS.HmacSHA256(`${this.state.password}`,"Secret Passphrase Soprano").toString();
                    let url  = '/ws/auth/changePwd';
                    let body = `nom_utilisateur=${this.context.user.nom_utilisateur}&mot_de_passe=${this.context.user.password}&new_mot_de_passe=${hachedPwd}&code_magasin=${this.context.store.code}`;
                    let fetchChangePwd = new Promise(api.auth.change_password(this.context.user.token, url, body));

                    fetchChangePwd.then((jsonData) => {
                        if (jsonData.status){
                            this.context.onLogin(this.context.user,this.state.new_password,'Mot de passe modifié avec succès');
                            this.props.history.push("/accueil");
                        }else{
                            this.setState({password:'', msg:'Une erreur est survénu'});  
                        }
                    });
                    fetchChangePwd.catch((error) => {
                        this.setState(({
                            msg       :'Une erreur est survénu',
                            error_msg : error
                        }))
                    })
                    break;
                } else { 
                    this.setState({
                        password:'', 
                        msg     :'Mot de passe incorrect'
                    });
                    break;
                }
            }
            default: { break;}
        }
    }

    // On every click on the keyboard buttons a caracter will be added to the password state
    addCharacterToPassword = (val) => {
        if (this.state.password.length >= 4) return null;
        this.setState((state)=>{
            return {
                password : state.password.concat(val),
                msg:''
            }
        })
    }

    /* On every click on the left-bottom button of the keyboard 
    * if the password state is empty return to the ("/utilisateur") page
    * else remove caracter from password state
    */
    deleteCharacterFromPassword = () => {
        if (this.state.password === "") {
            this.context.onLogout();
            this.props.history.push("/utilisateur")
        }
        this.setState((state)=>{
            return {
                password : state.password.slice(0, -1),
                msg:''
            }
        })
    }

    renderCircleClass = (index)=>{
        return this.state.password.length >= index ? "big circle icon" : "big circle outline icon"
    }

    // the message that will be rendred in the case of password editing error
    renderMsg = () =>{
        return this.state.msg ==='' ? null : 
            <div className="message-container" >
                <div className="ui negative message">
                    <i className="close icon" onClick={()=>{this.setState({msg: ''})}}></i>
                    <div className="header">
                        {this.state.msg}
                    </div>
                </div>
            </div>
    }

    // the informative message of the steps to intialize a user password 
    renderInitializationPwdMsg = () =>{
         if (this.state.initializePwd) {
            return (
                <div className="ui info message">
                    <div className="header">
                        Réinitialisation du mot de passe
                    </div>
                    <ul className="list">
                        <li className={this.state.initializePwd_step === 1 ? "current-step": "" } >Entrez votre ancien mot de passe.</li>
                        <li className={this.state.initializePwd_step === 2 ? "current-step": "" } >Entrez votre nouveau mot de passe.</li>
                        <li className={this.state.initializePwd_step === 3 ? "current-step": "" } >Confirmez votre nouveau mot de passe.</li>
                    </ul>
                </div>
            )
        }
    } 

	render () {
        return (
            <div className="pave-numerique">
                <div className="container">
                    {this.renderInitializationPwdMsg()}
                    <div className="pwd-container">
                            {this.renderMsg()}
                        <i className={this.renderCircleClass(1)}/>
                        <i className={this.renderCircleClass(2)}/>
                        <i className={this.renderCircleClass(3)}/>
                        <i className={this.renderCircleClass(4)}/>
                    </div>
                    <div className="number-container">
                        <button onClick={()=>{this.addCharacterToPassword("1")}} className="circular ui icon button btn-circle">1</button>
                        <button onClick={()=>{this.addCharacterToPassword("2")}} className="circular ui icon button btn-circle">2</button>
                        <button onClick={()=>{this.addCharacterToPassword("3")}} className="circular ui icon button btn-circle">3</button>
                        <button onClick={()=>{this.addCharacterToPassword("4")}} className="circular ui icon button btn-circle">4</button>
                        <button onClick={()=>{this.addCharacterToPassword("5")}} className="circular ui icon button btn-circle">5</button>
                        <button onClick={()=>{this.addCharacterToPassword("6")}} className="circular ui icon button btn-circle">6</button>
                        <button onClick={()=>{this.addCharacterToPassword("7")}} className="circular ui icon button btn-circle">7</button>
                        <button onClick={()=>{this.addCharacterToPassword("8")}} className="circular ui icon button btn-circle">8</button>
                        <button onClick={()=>{this.addCharacterToPassword("9")}} className="circular ui icon button btn-circle">9</button>

                        <button onClick={this.deleteCharacterFromPassword} className="circular ui icon button btn-circle massive"><i className="undo icon"></i></button>
                        <button onClick={()=>{this.addCharacterToPassword("0")}} className="circular ui icon button btn-circle">0</button>
                        <button onClick={this.state.initializePwd ? this.initializePwd : this.login} className="circular ui icon button btn-circle massive"><i className="check circle icon"></i></button>
                    </div>
                </div>
                <Copyright />
            </div>
        )            
        
	}
}

export default Home;