import React            from 'react';
import UserCard         from './UserCard';
import { Loader,Error } from './common/common';
import appContext       from '../context/app-context';
import api              from 'soprano-ws-client';
import Copyright from './common/Copyright';
import ClientLogo from './common/ClientLogo';

class UsersList extends React.Component {
    static contextType = appContext
    state = {
        users : [],
        status : "loading",
        error_msg:'',
    }

    // Get users list from api when component did mount
    componentDidMount = () =>{
        this.getUsersList();
    }

    // Get users list from server
    getUsersList = () =>{
        let fetchUsersList = new Promise(api.user.list('token',`/ws/user/list?code_magasin=${this.context.store.code}`));
        let offlineData    = JSON.parse(localStorage.getItem("offlineData"));
        let urlIndex   = offlineData.users.findIndex(Element =>{
            return Element.url === `GET/ws/user/list?code_magasin=${this.context.store.code}`;
        })
        fetchUsersList.then((jsonData) => {
            if (jsonData.status === true){
                if(urlIndex === -1) {
                    offlineData.users = offlineData.users.concat({url:`GET/ws/user/list?code_magasin=${this.context.store.code}`,list: jsonData.data})
                }else{
                    offlineData.users[urlIndex].list = jsonData.data;
                }
                localStorage.setItem('offlineData',JSON.stringify(offlineData));
                this.setState({
                    users: jsonData.data,
                    status: "success"
                });
            }else{
                this.setState({
                    status: "error"
                });
            }
        })
        fetchUsersList.catch((error) => {
            this.setState({ 
                users     : urlIndex === -1 ? [] :  offlineData.users[urlIndex].list,
                status    : 'success', 
                error_msg : error,
            })
        })

    }

    // Render users list fetched from server
    renderUsers = () => {
        return this.state.users.map((user, index)=> {
            return <UserCard user={user} key={index} />
        });
    }

    /** Switch status state Render Utilisateur page 
     * loading : render the <Loader /> component
     * error : render the <Error /> component
     * ok : render users 
     */
    render () {

        switch(this.state.status) {
            case 'loading':
                return (
                    <Loader />
                );
            case 'error':
                return (
                    <Error />
                );
            default :
            return (
                <div className="utilisateur">
                        <ClientLogo/>                
                        <div className="container">
                            {this.renderUsers()}                
                        </div>
                        <Copyright/>                 
                </div>
            )            
        }
	}
}

export default UsersList;