import React, {Component} from "react";
import {Link} from 'react-router-dom';
import AppContext from "../context/app-context";

class UserCard extends Component {
    static contextType = AppContext;

    render () {
        return (
            <Link to={{pathname: "/mot-de-passe",state: { initializePwd: false }}}>
            <div className="ui card" onClick={()=>this.context.selectUser(this.props.user)}>
                <div className="content">
                    <h1 className="header">{this.props.user.nom_utilisateur}</h1>
                </div>
                <div>
                    <img src={this.context.basename + this.props.user.avatar} className="ui circular image" alt='user avatar' />
                </div>
            </div>
            </Link>
        )
    }
}

export default UserCard;