import React, { Component } from 'react';
import AppContext           from './app-context';
import {toast}              from 'react-toastify'

class GlobalState extends Component {
    state ={
        user: JSON.parse(sessionStorage.getItem('user')) === null ? 
            { 
              nom: null,
              password:"1234",
              avatar:"/app/images/avatar-user-male.png",
              connected: false,
              token: null, 
              information : null,
            } : JSON.parse(sessionStorage.getItem('user')),
        store: JSON.parse(sessionStorage.getItem('store')) === null ?
            {
              adresse: "",
              code: "",
              id: "1",
              image: "",
              nom: "",
              type: ""
            } : JSON.parse(sessionStorage.getItem('store')), 
        error_msg : '',
        version   : "V 0.1.1",
        basename  : "/app"      
      };
    
      // When this function is called, it will update the user state to the selected user
      selectUser = (user) => {  
        //console.log("*************  select User  **************", user);
        // The user selected isn't connected yet 
        user.connected = false;
        this.setState({user},() => {sessionStorage.setItem('user', JSON.stringify(this.state.user))});
      }
    
      /** this function will be called two times when the user want to:
       * login: login to the app by changing the state connected to true and save the password
       * change_password: updating the password of the user 
       */
      onLogin = (user,password,msg) => {  
        //console.log("*************  onLogin  **************", user);
        user.acl       = { 
                          vente       : "undefined",
                          definition  : "undefined", 
                          stock       : "undefined", 
                          stockadjust : "undefined", 
                          utilisation : "undefined",
                          preparation : "undefined",  
                          approvisionnement : "undefined",
        };
        user.connected = true;
        user.password  = password;
        user.nom_utilisateur = this.state.user.nom_utilisateur; 
        this.notify(msg,'info');
        this.setState({user}, ()=> {
          sessionStorage.setItem('user', JSON.stringify(this.state.user));
          sessionStorage.setItem('token',this.state.user.token);
         });
      };
    
      onLogout = () =>{
          //console.log("*************  onLogout  **************");
          if (this.state.user.connected){
            this.notify('Utilisateur déconnecté','info');
          }
          let user = { 
              nom: null,
              password:"456879",
              image:"/app/images/avatar-user-male.png",
              connected: false,
              token: null, 
              information : null,
              acl : { 
                vente       : "undefined",
                definition  : "undefined", 
                stock       : "undefined", 
                stockAdjust : "undefined", 
                utilisation : "undefined",
                preparation : "undefined",  
                approvisionnement : "undefined",
              }
              };
          this.setState({ user }, ()=>{sessionStorage.removeItem('user')});
      };
    
      // When this function is called, it will update the store state to the one selected by user
      selectStore = (store) =>{
        //console.logconsole.log("*************  select Store  **************", store);
        this.setState({store},()=>{sessionStorage.setItem('store', JSON.stringify(this.state.store))})
      };

      /** This function will set the access to each module to show link or not 
       * this function will be called only when the user is connected and will prevent fetching access
       * in the module in every redirection to Home page
       */
      setAccess = (module,access) => {
        let user = Object.assign({}, this.state.user);
        user.acl[module] = access;
        this.setState({ user });
      }

      /** when this function is called it will show a message to the user with the type specified
       * msg  : the messge will be shown to the user
       * type : type of message 'success', 'error', 'warn', 'info'
       *  */  
      notify = (msg,type) => {
          let msgPosition = {position: toast.POSITION.BOTTOM_RIGHT}
          switch(type) {
              case 'success':
                  toast.success(msg,msgPosition); break;
              case 'error':
                  toast.error(msg,msgPosition); break;
              case 'warn':
                  toast.warn(msg,msgPosition); break;
              case 'info':
                  toast.info(msg,msgPosition); break;
              default : toast(msg,msgPosition);
          }
      };

  render() {
    return (
      <AppContext.Provider
        value={{
            user        : this.state.user,
            store       : this.state.store,
            selectStore : this.selectStore,
            selectUser  : this.selectUser,
            alerts_number    : this.state.alerts_number,
            version     : this.state.version,
            basename    : this.state.basename,
            onLogin  : this.onLogin,
            onLogout : this.onLogout,
            setAccess: this.setAccess,
            notify   : this.notify,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default GlobalState;