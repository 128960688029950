import React from 'react';
import appContext from '../../context/app-context';


class ClientLogo extends React.Component {

    static contextType = appContext;

    render () {
        return (
            <div className= "app-header" >
                <div className="logo-container">
                    <img src={`${this.context.basename}/images/logo-soprano.jpg`} className="image" alt="" />
                </div>
            </div>
        )
    }
}
export default ClientLogo;